<template>
  <div>
    <DateFilter @submit="search" />
    <div v-if="dateText.start===dateText.end">
      ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span>
      จำนวน <span class="text-primary">{{items.length}}</span> รายการ
    </div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
      จำนวน <span class="text-primary">{{items.length}}</span> รายการ
    </div>

    <div class="transaction-table position-relative">

      <table class="table table-sm table-bordered table-hover table-info">
        <thead>
          <tr>
            <th width="180">วันที่ - เวลา</th>
            <th>รายละเอียด</th>
            <th width="150">เงินเข้า</th>
            <th width="150">เงินออก</th>
            <th width="150">คงเหลือ</th>
            <th width="200">หมายเหตุ</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item._id">
            <td class="text-left">{{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}</td>
            <td>{{item.description}}</td>
            <td class="text-right text-success">{{item.amounts.deposit | amountFormat(2)}}</td>
            <td class="text-right text-danger">{{item.amounts.withdraw | amountFormat(2)}}</td>
            <td class="text-right text-primary">{{item.amounts.after | amountFormat(2)}}</td>
            <td class="text-center">{{item.remarks}}</td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length">
          <tr>
            <th colspan="2" class="text-center">รวม</th>
            <th class="text-right text-success">{{sum.income | amountFormat(2, '0.00')}}</th>
            <th class="text-right text-danger">{{sum.outgoing | amountFormat(2, '0.00')}}</th>
            <th class="text-right" :class="[{'text-success':sum.total>0}, {'text-danger':sum.total<0}]">{{sum.total | amountFormat(2, '0.00')}}</th>
          </tr>
        </tfoot>
      </table>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/report/components/DateFilter'
import ReportService from '@/services/ReportService'
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'

export default {
  name: 'Transaction',
  components: {
    DateFilter
  },
  data() {
    return {
      data: null,
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null
      }
    }
  },
  computed: {
    items() {
      return this?.data?.items || []
    },
    dateText() {
      if(!this.data)
        return {
          start: null,
          end: null
        }

      return {
        start: moment(this.data.date.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.data.date.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      if(!this.data)
        return ''

      return this.data.date.total || 0
    },
    sum() {
      return this.items.reduce((sum, item)=>{
        sum.income += item.amounts.deposit
        sum.outgoing += item.amounts.withdraw
        sum.total += (item.amounts.deposit - item.amounts.withdraw)
        return sum
      }, {
        income: 0,
        outgoing: 0,
        total: 0
      })
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        ...filters,
        startDate: filters.date.start,
        endDate: filters.date.end,
      }
      this.loadReports()
    },
    loadReports() {
      this.isLoading = true
      ReportService.getTransactions(this.filters).then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดรายงานไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.transaction-table {
  background-color: #FFF;
  min-height: 50px;

  table {
    thead {
      tr {
        th {
          font-size: 85%;
          vertical-align: middle;
          text-align: center;
        }
      }
    }
    tbody {
      background-color: #FFF;
      tr {
        td {
          vertical-align: middle;
          font-size: 85%;
        }
      }
    }
  }
}
</style>
